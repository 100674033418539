import polyline from "@mapbox/polyline";
import greatCircle from "@turf/great-circle";
import { point } from "@turf/helpers";
import moment from "moment";

export const getItemID = (item) => item?.hotel_id || item?.fsq_id || item?.productCode || item?.alpha3 || item?.id || item?._id || item?.normalised_city;
export const getItemType = (item) =>
	item?.hotel_id || item?.type === "accommodation"
		? "accommodation"
		: item?.fsq_id || item?.id?.includes?.("poi") || item?.type === "activity"
		? "activity"
		: item?.type === "cuisine"
		? "cuisine"
		: item?.segments
		? "transport"
		: item?.type === "city"
		? "city"
		: "tour";

export const getItemColor = (type) =>
	type === "activity" || type === "tour"
		? "primary-3"
		: type === "cuisine"
		? "primary-4"
		: type === "accommodation"
		? "primary-1"
		: type === "transport"
		? "primary-2"
		: "black";

export const getItemColorName = (type) =>
	type === "activity" || type === "tour"
		? "orange"
		: type === "cuisine"
		? "purple"
		: type === "accommodation"
		? "green"
		: type === "transport"
		? "magenta"
		: "black";

export const getItemColorCode = (type) =>
	type === "activity" || type === "tour"
		? "#FFC14A"
		: type === "cuisine"
		? "#9376E8"
		: type === "accommodation"
		? "#13C892"
		: type === "transport"
		? "#EA5681"
		: "#0F3E4A";

export const getPartnerColor = (type) =>
	type === "booking"
		? "#0C3B7C"
		: type === "hostelworld"
		? "#F37349"
		: type === "airbnb"
		? "#FF385D"
		: type === "viator"
		? "#186B6D"
		: type === "getyourguide"
		? "#FF5533"
		: type === "tripadvisor"
		? "#34E0A1"
		: "#0F3E4A";

export const getUserType = (value) => {
	return value ? "creator" : "user";
};

export const getTrackingLabelForType = (type) => {
	return type === "accommodation" ? "sleep" : type === "activity" ? "see_do" : type === "cuisine" ? "eat_drink" : "article";
};

export const getItemIcon = (type) =>
	type === "accommodation"
		? "bed--outlined"
		: type === "activity"
		? "todo--outlined"
		: type === "cuisine"
		? "cuisine"
		: type === "tour"
		? "tour"
		: type === "transport"
		? "transport--outlined"
		: type === "city"
		? "marker"
		: "article";

export const getGeocodes = (item) => {
	return item?.fsq_id
		? [Number(item?.geocodes?.main?.longitude), Number(item?.geocodes?.main?.latitude)]
		: item?.metadata && item?.lng && item?.lat
		? [Number(item?.lng), Number(item?.lat)]
		: [Number(item?.longitude), Number(item?.latitude)];
};

export const getTravelModeLabel = (value) =>
	value === "plane"
		? "Plane"
		: value === "car"
		? "Car"
		: value === "rentalcar"
		? "Rental car"
		: value === "train"
		? "Train"
		: value === "bus"
		? "Bus"
		: value === "ferry"
		? "Ferry"
		: "";

export const getTransports = (destinations, transports) => {
	const bookedPaths = [];
	const nonBookedPaths = [];
	destinations?.map((destination, index) =>
		transports?.[destination._id]?.destinationTo === destinations?.[index + 1]?._id && transports?.[destination._id]?.segments?.length > 0
			? bookedPaths.push(
					//@ts-ignore
					transports?.[destination._id]?.segments?.map((segment) =>
						transports?.[destination._id]?.isStipplTransport && !!segment.path?.coordinates
							? segment.path
							: !transports?.[destination._id]?.isStipplTransport && !!segment.path
							? polyline.toGeoJSON(segment.path)
							: greatCircle(
									point([destination?.longitude, destination.latitude]),
									point([destinations[index + 1]?.longitude, destinations[index + 1]?.latitude])
							  )
					)
			  )
			: index < destinations?.length - 1
			? nonBookedPaths.push(
					greatCircle(point([destination?.longitude, destination.latitude]), point([destinations[index + 1]?.longitude, destinations[index + 1]?.latitude]))
					// //@ts-ignore
					// polyline.toGeoJSON(
					//   polyline.encode([
					//     [destination.latitude, destination.longitude],
					//     [destinations[index + 1].latitude, destinations[index + 1].longitude],
					//   ])
					// )
			  )
			: null
	);
	return { bookedPaths, nonBookedPaths };
};

export const getPlannedItems = (currentTrip) => {
	const allAccommodations =
		currentTrip.destinations?.flatMap?.(({ allAccommodations }) => allAccommodations?.filter((item) => item?.hotel_id || item?.address)) || [];
	const allActivities =
		currentTrip.destinations?.flatMap?.(({ allActivities }) =>
			allActivities?.filter((item) => getItemType(item) === "activity" && (item?.fsq_id || item?.address))
		) || [];
	const allCuisine = currentTrip.destinations?.flatMap?.(({ allCuisine }) => allCuisine) || [];

	return [...(currentTrip?.destinations || []), ...allAccommodations, ...allActivities, ...allCuisine];
};

export const getRange = (startDate, endDate, type) => {
	let fromDate = moment(startDate);
	let toDate = moment(endDate);
	let diff = toDate.diff(fromDate, type);
	let range = [];
	for (let i = 0; i <= diff; i++) {
		range.push(moment(startDate).add(i, type));
	}
	return range;
};

export const getMapMarkerIcon = (cat) => {
	switch (cat) {
		//Sights & Landmarks
		case "6030e3ee749499e147722a95":
			return "landmarksMarker";
		//Nature & Outdoors
		case "6030e3ee749499e147722a99":
			return "natureMarker";
		//Tours & Attractions
		case "6030e3ee749499e147722a96":
			return "toursMarker";
		//Culture & Activities
		case "637e116eddc2e1237e0f1d27":
			return "cultureMarker";
		//Sports & Wellness
		case "6030e3ee749499e147722a9b":
			return "sportsMarker";
		//Beaches & Lakes
		case "637e11aaddc2e1237e0f1d28":
			return "beachMarker";
		//Shopping & Souvenirs
		case "6030e3ee749499e147722a9a":
			return "shoppingMarker";

		//Hotels
		case "6030e3ee749499e147722a8f":
			return "hotelMarker";
		//Hostels
		case "6030e3ee749499e147722a91":
			return "hostelMarker";
		//Campgrounds
		case "61616a9a33b6745519ec19b8":
			return "campgroundsMarker";
		//Villas
		case "637e1204ddc2e1237e0f1d29":
			return "villaMarker";
		//Resorts
		case "637e1280ddc2e1237e0f1d32":
			return "resortMarker";
		//Apartments
		case "6030e3ee749499e147722a90":
			return "apartmentMarker";
		//Bed & Breakfast
		case "6030e3ee749499e147722a93":
			return "bbMarker";

		//Breakfast
		case "637e1293ddc2e1237e0f1d33":
			return "breakfastMarker";
		//Lunch
		case "637e129fddc2e1237e0f1d34":
			return "lunchMarker";
		//Dinner
		case "637e12a7ddc2e1237e0f1d35":
			return "dinnerMarker";
		//Drinks & Nightlife
		case "637e12b1ddc2e1237e0f1d36":
			return "nightlifeMarker";
		//Coffee & Tea
		case "637e12bcddc2e1237e0f1d37":
			return "coffeeMarker";
		//Desserts & Sweets
		case "637e12d6ddc2e1237e0f1d38":
			return "sweetsMarker";

		//general accommodations
		case "accommodation":
			return "sleepMarker";
		//general see & do
		case "activity":
			return "seedoMarker";
		//general cuisine
		case "cuisine":
			return "cuisineMarker";

		default:
			return "citymarker";
	}
};
