import TextTag from "components/cards/TextTag";
import Flag from "components/common/Flag";
import * as d3 from "d3";
import { MercatorCoordinate } from "mapbox-gl";
import moment from "moment";
import { AbsoluteFill, Easing, interpolate, useCurrentFrame } from "remotion";
import { computeCameraPosition } from "../computeCameraPosition";

let currentAltitude;
let currentBearing;
let currentPitch;

const IntroSequence = ({
  map,
  targetLngLat,
  startAltitude,
  endAltitude,
  startBearing,
  endBearing,
  startPitch,
  endPitch,
  INTRO_DURATION,
  currentTrip,
  scale,
}) => {
  const introFrame = useCurrentFrame();
  if (!map) return null;

  const animationPhase = (introFrame + 1) / INTRO_DURATION;

  currentAltitude = startAltitude + (endAltitude - startAltitude) * d3.easeCubicOut(animationPhase);
  // rotate the camera between startBearing and endBearing
  currentBearing = startBearing + (endBearing - startBearing) * d3.easeCubicOut(animationPhase);

  currentPitch = interpolate(animationPhase, [0, 1], [startPitch, endPitch], {
    easing: Easing.bezier(0.48, 0.2, 0.35, 0.82),
  });

  const correctedPosition = computeCameraPosition(currentPitch, currentBearing, targetLngLat, currentAltitude);

  // set the pitch and bearing of the camera
  const camera = map.getFreeCameraOptions();
  camera.setPitchBearing(currentPitch, currentBearing);

  // set the position and altitude of the camera
  camera.position = MercatorCoordinate.fromLngLat(correctedPosition, currentAltitude);

  // apply the new camera options
  map.setFreeCameraOptions(camera);

  const tripDuration = moment(currentTrip?.endDate).diff(currentTrip?.startDate, "days") || currentTrip?.duration;

  const fade = interpolate(animationPhase, [0, 0.9, 1], [1, 1, 0], {
    extrapolateRight: "clamp",
  });

  const bounce = interpolate(animationPhase, [0, 0.9, 1], [0, 0, -400], {
    easing: Easing.elastic(1.4),
    extrapolateRight: "extend",
  });

  return (
    <AbsoluteFill>
      <div className="reel-title" style={{ opacity: fade, transform: `scale(${scale})` }}>
        <div className="flags">
          {currentTrip?.countries?.map((country) => (
            <Flag country={country?.name} />
          ))}
        </div>

        <h1>
          <span>{currentTrip?.name}</span>
        </h1>
        <div className="badge-container">
          <TextTag icon="moon--outlined" text={`${tripDuration} nights`} color="black" />
          <TextTag icon="marker--outlined" text={`${currentTrip?.numberOfDestinations} destinations`} color="black" />
        </div>
      </div>
    </AbsoluteFill>
  );
};

export default IntroSequence;
