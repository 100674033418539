import along from "@turf/along";
import bearing from "@turf/bearing";
import { lineString } from "@turf/helpers";
import length from "@turf/length";
import simplify from "@turf/simplify";
import { MercatorCoordinate } from "mapbox-gl";
import { useEffect } from "react";
import { Easing, interpolate, useCurrentFrame } from "remotion";
import { computeCameraPosition } from "../computeCameraPosition";

const PathSequence = ({
  map,
  MAP_DURATION,
  reelData,
  path,
  index,
  startAltitude,
  startPitch,
  smooth = false,
  PATH_COLOR,
  scale,
}) => {
  const pathFrame = useCurrentFrame();
  const pathPhase = (pathFrame + 1) / MAP_DURATION;
  const routeDistance = length(path);
  const cameraPath = simplify(path);

  useEffect(() => {
    map?.setPaintProperty(`line-${index}`, "line-gradient", [
      "step",
      ["line-progress"],
      PATH_COLOR,
      easedPath,
      "rgba(0, 0, 0, 0)",
    ]);
    //Destination name text color
  }, [PATH_COLOR]);

  if (!map) return null;

  const maxAltitude = 8500000;
  const altitudeModifier = routeDistance < 600 ? 60000 : 95000;
  const prevPath = index > 0 ? lineString(reelData?.[index - 1]?.path) : undefined;
  const prevPathDistance = !!prevPath ? length(prevPath) : 200;

  const actualStartAltitude = prevPathDistance < 100 ? 50000 : startAltitude;

  const calcAltitude = Math.max(
    Math.min((routeDistance / MAP_DURATION) * altitudeModifier, maxAltitude),
    actualStartAltitude
  );

  const altitude = interpolate(pathPhase, [0, 0.5, 1], [actualStartAltitude, calcAltitude, startAltitude]);

  const maxPitch = 20;
  const pitchAltitudeThreshold = 2400000;
  const pitch = interpolate(altitude, [pitchAltitudeThreshold, 4000000], [startPitch, maxPitch], {
    extrapolateLeft: "clamp",
    extrapolateRight: "clamp",
  });

  const cameraAltitude = altitude;

  const easedPath = interpolate(pathPhase, [0, 1], [0, 1], { easing: Easing.bezier(0.48, 0.2, 0.35, 0.82) });
  const alongCameraPath = along(cameraPath, routeDistance * easedPath).geometry.coordinates;
  const alongMarkerPath = along(path, routeDistance * easedPath, { units: "kilometers" }).geometry.coordinates;

  if (map.getLayer(`line-${index}`)) {
    map?.setPaintProperty(`line-${index}`, "line-gradient", [
      "step",
      ["line-progress"],
      PATH_COLOR,
      easedPath,
      "rgba(0, 0, 0, 0)",
    ]);
    map?.setPaintProperty(`line-${index}`, "line-opacity", 1);
    map?.setPaintProperty("transportMarkerDot", "circle-opacity", 1);
    map?.setPaintProperty("transportMarkerIcon", "icon-opacity", 1);
    map?.setPaintProperty("transportProfilePicture", "icon-opacity", 1);

    map.getSource("transportMarkerSource").setData({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: alongMarkerPath,
      },
      properties: {},
    });

    const transportIcon = `${reelData?.[index]?.vehicle}Icon`;

    if (transportIcon === "undefinedIcon") {
      map?.setLayoutProperty("transportMarkerIcon", "icon-image", "");
      map?.setPaintProperty("transportMarkerDot", "circle-radius", 12 * scale);
    } else {
      map?.setLayoutProperty("transportMarkerIcon", "icon-image", transportIcon);
      map?.setPaintProperty("transportMarkerDot", "circle-radius", 32 * scale);
    }
  }

  const startHeading =
    bearing(reelData?.[index]?.path?.[0], reelData?.[index]?.path?.[reelData?.[index]?.path?.length - 1]) / 3;
  const endHeading =
    index < reelData?.length - 2
      ? bearing(
          reelData?.[index + 1]?.path?.[0],
          reelData?.[index + 1]?.path?.[reelData?.[index + 1]?.path?.length - 1]
        ) / 3
      : 0;

  const heading = interpolate(pathPhase, [0, 1], [startHeading, endHeading], {
    // easing: Easing.bezier(0.32, 0.23, 0.35, 0.91),
    // easing: Easing.bounce,
  });

  const lngLat = {
    lng: alongCameraPath[0],
    lat: alongCameraPath[1],
  };

  // compute corrected camera ground position, so that he leading edge of the path is in view
  const correctedPosition = computeCameraPosition(pitch, heading, lngLat, cameraAltitude);

  // set the pitch and bearing of the camera
  const camera = map.getFreeCameraOptions();
  camera.setPitchBearing(pitch, heading);

  // set the position and altitude of the camera
  camera.position = MercatorCoordinate.fromLngLat(correctedPosition, cameraAltitude);

  // apply the new camera options
  map.setFreeCameraOptions(camera);

  return <></>;
};

export default PathSequence;
