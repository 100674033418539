import along from "@turf/along";

import bearing from "@turf/bearing";
import { lineString } from "@turf/helpers";
import length from "@turf/length";
import { first, last } from "lodash";
import { MercatorCoordinate } from "mapbox-gl";
import { interpolate, spring, useCurrentFrame, useVideoConfig } from "remotion";
import { computeCameraPosition } from "../computeCameraPosition";

const MarkerSequence = ({ map, index, MARKER_DURATION, currentTrip, reelData, startAltitude, startPitch, scale }) => {
  const markerFrame = useCurrentFrame();
  const markerPhase = (markerFrame + 1) / MARKER_DURATION;
  const { fps } = useVideoConfig();

  if (!map) return null;

  const driver = spring({
    frame: markerFrame,
    fps,
    config: {
      mass: 1,
      stiffness: 300,
    },
  });
  const nextPath = index < reelData?.length - 1 ? lineString(reelData?.[index]?.path) : undefined;

  const nextPathDistance = !!nextPath ? length(nextPath) : 200;
  const endEltitude = nextPathDistance < 100 ? 50000 : 250000;
  const altitude =
    nextPathDistance < 100 ? interpolate(markerPhase, [0, 1], [startAltitude, endEltitude]) : startAltitude;

  const markerLngLat = [currentTrip?.destinations?.[index]?.longitude, currentTrip?.destinations?.[index]?.latitude];
  const prevPathEnd =
    index > 0 ? [last(reelData?.[index - 1]?.path)?.[0], last(reelData?.[index - 1]?.path)?.[1]] : markerLngLat;
  const nextPathStart =
    index < reelData?.length - 1 ? [reelData?.[index]?.path?.[0]?.[0], reelData?.[index]?.path?.[0]?.[1]] : prevPathEnd;

  const cameraPath = lineString([prevPathEnd, markerLngLat, nextPathStart]);
  const cameraDistance = length(cameraPath);
  const alongPath = along(cameraPath, cameraDistance * markerPhase).geometry.coordinates;

  const startHeading =
    reelData?.[index]?.path?.length > 0
      ? bearing(first(reelData?.[index]?.path), last(reelData?.[index]?.path)) / 3
      : 0;

  const endHeading =
    index < reelData?.length - 1 ? bearing(first(reelData?.[index]?.path), last(reelData?.[index]?.path)) / 3 : 0;

  const heading = interpolate(markerPhase, [0, 1], [startHeading, endHeading], {
    // easing: Easing.bezier(0.32, 0.23, 0.35, 0.91),
  });

  const lngLat = {
    lng: alongPath[0],
    lat: alongPath[1],
  };

  // compute corrected camera ground position, so that he leading edge of the path is in view
  const correctedPosition = computeCameraPosition(startPitch, heading, lngLat, altitude);

  // set the pitch and bearing of the camera
  const camera = map.getFreeCameraOptions();
  camera.setPitchBearing(startPitch, heading);

  // set the position and altitude of the camera
  camera.position = MercatorCoordinate.fromLngLat(correctedPosition, altitude);

  // apply the new camera options
  map.setFreeCameraOptions(camera);

  const fadeIn = interpolate(driver, [0, 1], [0, 1], {
    extrapolateRight: "clamp",
  });
  const markerRadius = interpolate(driver, [0, 1], [0, 48 * scale]);
  const destinationNameTranslate = interpolate(driver, [0, 1], [0, -88 * scale]);
  const pulseOpacity = interpolate(markerFrame, [0, 4, MARKER_DURATION / 1.75], [0, 1, 0], {
    extrapolateRight: "clamp",
  });
  const pulseRadius = interpolate(markerFrame, [0, 8], [0, 100 * scale]);
  const countSize = interpolate(driver, [0, 1], [0, 40 * scale], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
  });

  //Animate marker background
  map?.setPaintProperty(`marker-${index}`, "circle-opacity", fadeIn);
  map?.setPaintProperty(`marker-${index}`, "circle-radius", markerRadius);

  //Animate marker number
  map?.setPaintProperty(`countText-${index}`, "text-opacity", fadeIn);
  map?.setLayoutProperty(`countText-${index}`, "text-size", countSize);

  //Animate pulse
  map?.setPaintProperty(`pulse-${index}`, "circle-opacity", pulseOpacity);
  map?.setPaintProperty(`pulse-${index}`, "circle-radius", pulseRadius);

  //Animate destination name
  map?.setPaintProperty(`destinationName-${index}`, "text-opacity", fadeIn);
  map?.setPaintProperty(`destinationName-${index}`, "icon-opacity", fadeIn);
  map?.setPaintProperty(`destinationName-${index}`, "icon-translate", [0, destinationNameTranslate]);
  map?.setPaintProperty(`destinationName-${index}`, "text-translate", [0, destinationNameTranslate]);

  return <></>;
};

export default MarkerSequence;
