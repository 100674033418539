export const Colors = {
  main1: "#13c892",
  main2: "#ea5681",
  main3: "#ffc14a",
  main4: "#9376e8",
  error: "#eb5757",
  black: "#0F3E4A",
  white: "#FFFFFF",
  greyDark: "#658C96",
  greyLight: "#8DB2BB",
  greyBorder: "#DDE9ED",
  bgDark: "#ECF6F9",
  bgMedium: "#F1F8FA",
  bgLight: "#F9FCFD",
};
